<template>
    <v-expansion-panel v-on="$listeners">
        <v-expansion-panel-header>
            <div class="font-weight-bold header">Launch screen</div>
            <template v-slot:actions>
                <v-icon left class="icon">
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <radio-group-row
                v-model="value.imageType"
                :items="[{value: 'single', label: 'Single Image'}, {value: 'tiled', label: 'Tiled'}]"
                title="Single image or tiled?"
            />
            <v-divider/>
            <template v-if="value.imageType === 'single'">
                <launch-screen-single-image
                    v-model="value.singleImage"
                    :line-number-props="lineNumberProps"
                />
            </template>
            <template v-if="value.imageType === 'tiled'">
                <launch-screen-tiled
                    v-model="value.tiled"
                    :line-number-props="lineNumberProps"
                    v-on="$listeners"
                />
            </template>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import LineNumber from "components/LineNumber";
import TextTooltip from "components/text_tooltip";
import LaunchScreenSingleImage from "./launch_screen_single_image";
import LaunchScreenTiled from "./launch_screen_tiled";
import RadioGroupRow from "components/create_store/fields/RadioGroupRow";

export default {
    name: "LaunchScreenSection",
    components: {
        LineNumber,
        TextTooltip,
        LaunchScreenSingleImage,
        LaunchScreenTiled,
        RadioGroupRow,
    },
    props: {
        value: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            lineNumberProps: {
                md: '1',
                sm: '2',
                lg: '1',
            },

        };
    }
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
