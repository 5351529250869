var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("Launch screen"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c("radio-group-row", {
            attrs: {
              items: [
                { value: "single", label: "Single Image" },
                { value: "tiled", label: "Tiled" },
              ],
              title: "Single image or tiled?",
            },
            model: {
              value: _vm.value.imageType,
              callback: function ($$v) {
                _vm.$set(_vm.value, "imageType", $$v)
              },
              expression: "value.imageType",
            },
          }),
          _c("v-divider"),
          _vm.value.imageType === "single"
            ? [
                _c("launch-screen-single-image", {
                  attrs: { "line-number-props": _vm.lineNumberProps },
                  model: {
                    value: _vm.value.singleImage,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "singleImage", $$v)
                    },
                    expression: "value.singleImage",
                  },
                }),
              ]
            : _vm._e(),
          _vm.value.imageType === "tiled"
            ? [
                _c(
                  "launch-screen-tiled",
                  _vm._g(
                    {
                      attrs: { "line-number-props": _vm.lineNumberProps },
                      model: {
                        value: _vm.value.tiled,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "tiled", $$v)
                        },
                        expression: "value.tiled",
                      },
                    },
                    _vm.$listeners
                  )
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }